import './index.module.scss';
import Layout from '../../layout';
import Header from '../../components/header/header';
import ContactInfoProps from '../../components/contact-info/contact-info';
import Footer from '../../components/footer/footer';
import BannerInner from '../../components/banner-inner/banner-inner';
import ContactForm from '../../components/contact-form/contact-form';
import banner from '../../assets/images/services-banner.jpg';
import Services from '../../components/services/services';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        bannerImage={banner}
        caption={<h2 className="x-large-heading color-white">Our Services</h2>}
      />
      <Services />
      <ContactForm />
      <ContactInfoProps />
      <Footer />
    </Layout>
  );
}

export default Index;
